<template>
  <div>
    <HeaderBar/>
    <Header steps="true" stepNumb="6"/>
    <Steps step_a="6" completed="[true, true, true, true, true]"/>
    <div class=" background-form"> 
      <div class="container">
        <div class="bloque-informacion-basica form-paso2" id="paso6">
          <div class="row align-items-start">
            <div class="informacion-basica-titulo col-12">
              <h2 class="informacion-basica-texto">{{$t('ConfirmationProcess.title')}}</h2>	  
              <div class="divider-wrap">
                <div class="divider-middle mx-auto"></div>
              </div>
            </div>
            <div class="bloque-info-lines no-form"> 
              <p class="bloque-info col-12 text-center">{{$t('ConfirmationProcess.descriptionOne')}}</p>
              <p class="bloque-info col-12 text-center" v-html="$t('ConfirmationProcess.descriptionTwo')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>

import HeaderBar from '@/components/HeaderBar.vue'
import Header from '@/components/Header.vue'
import Steps from '@/components/Steps.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'confirmationProcess',
  components: {
    HeaderBar,
    Header,
    Steps,
    Footer
  }
}
</script>
